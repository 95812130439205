import { dashboardActions, itemActions } from '@pickles/shared/redux/actions';
import { NextPage } from 'next';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ItemHooks, UserHooks } from '../app/redux/hooks';
import { Layout } from '../components';
import BrowseInventory from '../components/BrowseInventory';
import Description from '../components/Description';
import Specialization from '../components/Homepage/Specialization';
import MainBanner from '../components/MainBanner';
import MetaTags from '../components/Metatags';
import SearchBarWithSuggestion from '../components/Navbar/SearchBarWithSuggestion';
import Container from '../components/Shared/Container';
import { MobileProfileHeader } from '../components/Shared/Mobile/MobileProfileHeader';
import { MobileAuthBlock } from '../components/Shared/MobileAuthBlock';
import withStrapiDataValidator from '../hocs/withStrapiDataValidator';
import useScreenSize from '../hooks/useScreenSize';
import { useSetNavigationData } from '../hooks/useSetNavigationData';
import sizes from '../util/sizes';
import { DownloadBanner, UpcomingAuctions, VehiclesList } from './../components/Homepage';
import { getHomepage } from './api/homepage';

export const getServerSideProps = async () => {
  let data = {};
  try {
    data = await getHomepage();
  } catch (error) {
    data = {};
  }
  return {
    props: {
      data,
    },
  };
};

const Home: NextPage = (props: any) => {
  const dispatch = useDispatch();

  const data = props?.data?.data?.attributes;
  const footerData = props?.footerData?.data?.attributes;
  const navigationData = props?.navigationData?.data?.attributes;

  useSetNavigationData(navigationData);

  const account = UserHooks.useUserAccount();
  const latestVehicles = ItemHooks.useLatestVehicles();
  const { t } = useTranslation();

  const accountId = account?.id;

  useEffect(() => {
    dispatch(dashboardActions.getDashboard());
    dispatch(itemActions.getRecommendationsList(1));
    dispatch(itemActions.getLatestVehicles());
  }, [dispatch]);

  useEffect(() => {
    if (accountId) {
      // dispatch(ebidAuctionActions.watchEbidAuctionSocket());
      // dispatch(ebidAuctionActions.getEbidAuctionsConfig());
      dispatch(itemActions.getFavoritesIds());
      dispatch(itemActions.getFavoritesAll());

      // return () => {
      //   dispatch(ebidAuctionActions.watchEbidAuctionSocketCancel());
      // };
    }
  }, [dispatch, accountId]);

  const { banners, descriptionBlock, specialize, browseInventory, downloadBanner, seo } = data;

  const { maxWidth, minWidth, isMobile, screenSize } = useScreenSize();

  const itemCountPerGroup = useMemo(() => {
    return screenSize
      ? ['XXL'].includes(screenSize)
        ? 6
        : ['XL', 'L'].includes(screenSize)
        ? 4
        : 2
      : 1;
  }, [screenSize]);

  return (
    <Layout footerData={footerData} title="Home">
      <MetaTags title="Home" seo={seo} />
      {!isMobile ? (
        <MainBanner banners={banners} />
      ) : (
        <Container
          mx="20px"
          mb="20px"
          justifyContent="stretch"
          width="initial"
          style={{ position: 'relative' }}
        >
          <SearchBarWithSuggestion fullWidth />
        </Container>
      )}

      <Container style={{ maxWidth: sizes.XL, margin: '60px auto 0' }}>
        <Container mx={maxWidth.s ? 0 : '50px'} px={minWidth.xl ? 0 : minWidth.s ? '50px' : '8px'}>
          <Container>
            <Description info={descriptionBlock} />

            {account ? <MobileProfileHeader account={account} /> : <MobileAuthBlock />}

            <UpcomingAuctions />

            {!isMobile && <BrowseInventory data={browseInventory} />}

            <Specialization specialize={specialize} />

            <VehiclesList
              title={t('labels:recent_used_cars')}
              data={latestVehicles}
              itemCountPerGroup={itemCountPerGroup}
              rowCountPerGroup={2}
            />
          </Container>
        </Container>

        <Container mt={maxWidth.s ? 0 : '150px'} px={maxWidth.m ? 0 : maxWidth.xl ? '50px' : 0}>
          <DownloadBanner data={downloadBanner} />
        </Container>
      </Container>
    </Layout>
  );
};

export default withStrapiDataValidator(Home);
